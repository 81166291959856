import { instanceValue } from '@wix/wix-instance-parser-js';
import type { EditorAppContext } from '../../../types/editor-app-context.type';

type BiLoggerFactory = ReturnType<
  EditorAppContext['essentials']['biLoggerFactory']
>;
type Logger = ReturnType<BiLoggerFactory['logger']>;
type LogParams = Parameters<Logger['log']>[1];

let logger: Logger | undefined;

type CreateLoggerParams = {
  instanceId?: string;
  appDefId?: string;
  biToken?: string;
  context: EditorAppContext;
};

const createLogger = ({
  instanceId,
  appDefId,
  biToken,
  context,
}: CreateLoggerParams) =>
  context.essentials
    .biLoggerFactory({
      endpoint: 'blog-app-users',
    })
    .updateDefaults({
      src: 69,
      instance_id: instanceId,
      app_id: appDefId,
      biToken,
    })
    .logger();

const init = async (context: EditorAppContext, appDefId?: string) => {
  if (logger) {
    return;
  }

  const instance = await context.sdk.document.info.getAppInstance(
    context.appToken,
  );
  const instanceId = instanceValue.getValueFromInstance('instanceId', instance);
  const biToken = instanceValue.getValueFromInstance('biToken', instance);

  logger = createLogger({ instanceId, appDefId, biToken, context });
};

const reset = () => {
  logger = undefined;
};

const log = (params: LogParams) => {
  if (!logger) {
    throw new Error(
      'Service needs to be initialized using bi.init before triggering BI events',
    );
  }

  return logger.log(params);
};

const provisioningModalDisplayed = () =>
  log({
    evid: 329,
  });

const provisioningModalClosed = (
  action: string,
  shouldInstallMembersArea: boolean,
) =>
  log({
    evid: 330,
    action,
    option: shouldInstallMembersArea
      ? 'with_members_area'
      : 'without_members_area',
  });

const magicMigrationFailed = (error: string, migrationStep: string) =>
  log({
    evid: 409,
    error_message: error,
    migration_step: migrationStep,
  });

export default {
  init,
  reset,
  log,
  provisioningModalClosed,
  provisioningModalDisplayed,
  magicMigrationFailed,
};
