import { EditorSDK } from '@wix/platform-editor-sdk';
import { EditorAppContext } from '../../../types/editor-app-context.type';

type ModalOptions = Parameters<EditorSDK['editor']['openModalPanel']>[1];

export const openModalPanel = async (
  { sdk, isADI, appToken }: EditorAppContext,
  modal: ModalOptions,
) => {
  if (isADI) {
    return;
  }

  const { action, value } = await sdk.editor.openModalPanel(appToken, modal);
  return { action, value };
};
