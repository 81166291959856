import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { request, setInstance } from './request';

type TpaSettings = {};

export async function updateTPASettings(
  context: EditorAppContext,
  settings: TpaSettings,
) {
  const { sdk, appToken } = context;

  const instance = await sdk.document.info.getAppInstance(appToken);
  if (!instance) {
    return;
  }

  setInstance(instance);
  const response = await request.patch(
    '/v1/settings/instances',
    {
      settings,
      host: 'NA',
      states: ['PUBLISHED'],
    },
    {
      apiBaseUrl: '/_api/app-settings-service',
    },
  );

  return response;
}
